import type { LayoutProps } from '../../types';
import * as React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { adsRequest } from '../../utils/ads-request';
import { AdvertisementSlot } from '../../components/AdvertisementSlot';
import Container from '@mui/material/Container';
import { useAppContext } from '../../contexts/AppContext';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: '100%',
  width: '100%',
  padding: theme.spacing(0, 4), // different
  '@media screen and (max-width: 800px)': {
    padding: theme.spacing(0, 2),
  },
})) as typeof Container;

const SectionLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'auto',
  margin: theme.spacing(0, 'auto'), // different
}));

const SectionRight = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  '@media screen and (max-width: 1220px)': {
    display: 'none',
  },
}));

const AdvertisementSlotTop = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
}));

const AdvertisementSlotBottom = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  marginTop: theme.spacing(4),
}));

export const LayoutC = (props: LayoutProps) => {
  const { children } = props;
  const router = useRouter();
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const layoutAdsElements: Record<string, boolean> = {
    'idhb-right-300': !componentOptions.component_layout_right_ads_disabled || false,
    'idhb-right-small-300': !componentOptions.component_layout_right_middle_ads_disabled || false,
  };
  const adsList = Object.keys(layoutAdsElements).filter(key => !layoutAdsElements[key]);

  React.useEffect(() => {
    adsRequest(adsList);
  }, []);

  React.useEffect(() => {
    const { events } = router;
    const handleRouteChange = () => {
      if (adsList.length > 0 && !componentOptions.component_ads_disabled) {
        adsRequest(adsList);
      }
    };
  
    events.on('routeChangeComplete', handleRouteChange);
    events.on('hashChangeComplete', handleRouteChange);
  
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
      events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <Root>
      <SectionLeft>{children}</SectionLeft>
      {!componentOptions.component_ads_disabled && adsList.length > 0 && (
        <SectionRight>
          {componentOptions.component_layout_right_ads_disabled && <AdvertisementSlotTop elevation={1} slotIdentifier="idhb-right-300" variant="300x600" />}
          {componentOptions.component_layout_right_middle_ads_disabled && <AdvertisementSlotBottom elevation={1} slotIdentifier="idhb-right-small-300" variant="300x250" />}
        </SectionRight>
      )}
    </Root>
  );
};
